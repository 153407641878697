<template>
  <div>
    <header>
      <m-page-header title="售卖积分明细"></m-page-header>
    </header>
    <main class="main">
      <div class="main_time">
        <span>时间:</span>
        <el-select v-model="timeSelect" placeholder="请选择" size="small">
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </div>
      <div class="main_panel">
        <el-row :gutter="20">
          <el-col :span="6">
            <formSkeleton type="mini-text" :loading="loading">
              <div class="grid-content bg-purple">
                <div class="main_svg">
                  <span
                    ><svg
                      t="1620891372218"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="5183"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M378.368 56.32c-48.128 8.704-87.552 36.352-117.76 83.456-30.72 47.616-40.96 80.384-40.96 131.072 0.512 83.968 26.112 146.944 86.016 211.456 35.328 37.888 38.4 42.496 35.84 58.368-4.608 26.624-18.432 41.472-54.784 59.392-195.072 93.696-197.12 94.72-225.792 122.368-26.624 25.6-29.696 31.232-43.008 77.824-13.824 48.128-14.336 50.688-6.144 67.584 9.216 19.968 40.96 41.984 74.24 51.712 77.824 23.552 276.992 35.84 461.824 28.16 146.944-6.144 208.384-15.36 258.048-37.888 37.888-17.408 45.568-32.256 35.84-70.144-8.192-32.256-20.48-72.704-28.672-93.696-8.192-20.992-92.16-74.752-166.4-106.496-99.84-43.008-129.536-60.416-138.24-80.384-10.24-25.088-5.12-39.936 26.112-72.704 69.12-72.192 98.304-160.256 84.992-253.44-10.752-75.264-46.592-128.512-108.544-161.28-26.624-14.336-38.4-17.408-70.656-18.432-20.992-1.024-49.152 0.512-61.952 3.072z"
                        p-id="5184"
                        fill="#40b6e2"
                      ></path>
                      <path
                        d="M678.912 153.6c-39.936 7.168-38.912 6.144-30.208 34.304 10.752 34.816 14.848 136.704 6.656 171.008-10.24 42.496-32.256 81.92-68.608 121.856-23.04 24.576-33.792 40.448-33.792 49.152 0 19.456 43.008 60.416 74.24 71.68 92.672 31.744 148.992 65.536 193.024 114.688 25.6 28.16 39.936 63.488 47.104 114.688 3.072 19.968 5.632 24.064 12.288 22.016 4.608-1.536 30.72-8.192 57.344-15.36 77.312-19.456 75.264-18.432 79.36-51.712 9.728-75.264-16.384-103.424-157.696-171.52-40.448-19.456-76.288-38.912-79.872-43.008-3.584-4.608-6.656-15.872-7.168-25.088-0.512-14.336 4.608-23.04 25.6-46.592 52.224-58.88 71.68-114.176 66.048-189.44-7.68-112.128-81.92-175.104-184.32-156.672z"
                        p-id="5185"
                        fill="#40b6e2"
                      ></path></svg
                  ></span>
                </div>
                <div>
                  <p class="number">
                    <strong>{{ dataStatis.statis.user_total }}</strong>
                  </p>
                  <p>累计购买用户数</p>
                </div>
              </div>
            </formSkeleton>
          </el-col>
          <el-col :span="6">
            <formSkeleton type="mini-text" :loading="loading">
              <div class="grid-content bg-purple">
                <div class="main_svg">
                  <span
                    ><svg
                      t="1620891457490"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="6883"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M112.12673 284.089243a390.766 200.815 0 1 0 799.74654 0 390.766 200.815 0 1 0-799.74654 0Z"
                        p-id="6884"
                        fill="#f45d99"
                      ></path>
                      <path
                        d="M512 551.408545c-163.335019 0-303.791981-50.327222-365.869828-122.45189-21.856797 25.394367-34.003442 53.489238-34.003442 83.043345 0 113.491821 179.029466 205.495234 399.87327 205.495234s399.87327-92.003414 399.87327-205.495234c0-29.554106-12.145621-57.648978-34.003442-83.043345C815.791981 501.081323 675.335019 551.408545 512 551.408545z"
                        p-id="6885"
                        fill="#f45d99"
                      ></path>
                      <path
                        d="M512 784.985348c-165.466566 0-307.456441-51.64831-368.263341-125.285424-20.35049 24.644283-31.609928 51.751664-31.609928 80.20981 0 113.491821 179.029466 205.495234 399.87327 205.495234s399.87327-92.003414 399.87327-205.495234c0-28.459169-11.259438-55.565527-31.609928-80.20981C819.456441 733.337037 677.466566 784.985348 512 784.985348z"
                        p-id="6886"
                        fill="#f45d99"
                      ></path></svg
                  ></span>
                </div>
                <div>
                  <p class="number">
                    <strong>{{ dataStatis.statis.credit_total }}</strong>
                  </p>
                  <p>累积售卖积分</p>
                </div>
              </div>
            </formSkeleton>
          </el-col>

          <el-col :span="6">
            <formSkeleton type="mini-text" :loading="loading">
              <div class="grid-content bg-purple">
                <div class="main_svg">
                  <span
                    ><svg
                      t="1620891536016"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="10714"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m22.3 665.2l0.2 31.7c0 4.4-3.6 8.1-8 8.1h-28.4c-4.4 0-8-3.6-8-8v-31.4C401.3 723 359.5 672.4 355 617.4c-0.4-4.7 3.3-8.7 8-8.7h46.2c3.9 0 7.3 2.8 7.9 6.6 5.1 31.7 29.8 55.4 74.1 61.3V533.9l-24.7-6.3c-52.3-12.5-102.1-45.1-102.1-112.7 0-72.9 55.4-112.1 126.2-119v-33c0-4.4 3.6-8 8-8h28.1c4.4 0 8 3.6 8 8v32.7c68.5 6.9 119.9 46.9 125.9 109.2 0.5 4.7-3.2 8.8-8 8.8h-44.9c-4 0-7.4-3-7.9-6.9-4-29.2-27.4-53-65.5-58.2v134.3l25.4 5.9c64.8 16 108.9 47 108.9 116.4 0 75.3-56 117.3-134.3 124.1zM426.6 410.3c0 25.4 15.7 45.1 49.5 57.3 4.7 1.9 9.4 3.4 15 5v-124c-36.9 4.7-64.5 25.4-64.5 61.7z m116.5 135.2c-2.8-0.6-5.6-1.3-8.8-2.2V677c42.6-3.8 72-27.2 72-66.4 0-30.7-15.9-50.7-63.2-65.1z"
                        p-id="10715"
                        fill="#aa53fc"
                      ></path></svg
                  ></span>
                </div>
                <div>
                  <p class="number">
                    <strong>
                      <span class="huobi">{{ dataStatis.currency.symbol_left + ' ' }}</span>
                      <span>{{ dataStatis.statis.order_total_amount }}</span>
                      <span class="huobi">{{ ' ' + dataStatis.currency.symbol_right }}</span>
                    </strong>
                  </p>
                  <p>累积售卖金额</p>
                </div>
              </div>
            </formSkeleton>
          </el-col>

          <el-col :span="6">
            <formSkeleton type="mini-text" :loading="loading">
              <div class="grid-content bg-purple">
                <div class="main_svg">
                  <span
                    ><svg
                      t="1620891576615"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="12450"
                      width="200"
                      height="200"
                    >
                      <path
                        d="M766.5 172.4h-97.2c-1.4 35.2-30.4 63.6-66 63.6H420.7c-35.5 0-64.5-28.4-66-63.6h-97.2c-53.8 0-97.7 44-97.7 97.7V824c0 53.8 44 97.7 97.7 97.7h508.9c53.8 0 97.7-44 97.7-97.7V270.1c0.1-53.7-43.9-97.7-97.6-97.7z m-71.2 547.4H328.7c-13.5 0-24.4-10.9-24.4-24.4s10.9-24.4 24.4-24.4h366.7c13.5 0 24.4 10.9 24.4 24.4s-11 24.4-24.5 24.4z m0-148.9H328.7c-13.5 0-24.4-10.9-24.4-24.4s10.9-24.4 24.4-24.4h366.7c13.5 0 24.4 10.9 24.4 24.4s-11 24.4-24.5 24.4z m0-149H328.7c-13.5 0-24.4-10.9-24.4-24.4s10.9-24.4 24.4-24.4h366.7c13.5 0 24.4 10.9 24.4 24.4 0 13.5-11 24.4-24.5 24.4z"
                        p-id="12451"
                        fill="#1ae0a1"
                      ></path>
                      <path
                        d="M593.2 216.1H430.8c-24.2 0-43.8-19.6-43.8-43.8 0-24.2 19.6-43.8 43.8-43.8h162.3c24.2 0 43.8 19.6 43.8 43.8 0 24.3-19.6 43.8-43.7 43.8z"
                        p-id="12452"
                        fill="#1ae0a1"
                      ></path></svg
                  ></span>
                </div>
                <div>
                  <p class="number">
                    <strong>{{ dataStatis.statis.order_count_total }}</strong>
                  </p>
                  <p>累积订单数</p>
                </div>
              </div>
            </formSkeleton>
          </el-col>
        </el-row>
      </div>
    </main>
    <div class="line"></div>
    <div class="main">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-input
              placeholder="请输入订单编号"
              size="small"
              v-model="serachValue.keyword"
              clearable
              class="input-with-select"
            >
              <template #append>
                <el-button icon="el-icon-search" size="small" @click="getDataList"></el-button>
              </template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-popover placement="top" :width="400" v-model:visible="visible" trigger="manual">
              <template #reference>
                <el-button size="small" @click.stop="visible = !visible">高级搜索</el-button>
              </template>
              <div class="advancedSearch">
                <el-form ref="form" label-width="120px" label-position="top">
                  <el-form-item label="邮箱">
                    <el-input
                      size="small"
                      clearable
                      v-model="serachValue.email"
                      placeholder="请输入邮箱"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="订单编号">
                    <el-input
                      size="small"
                      clearable
                      v-model="serachValue.keyword"
                      placeholder="请输入订单编号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="订单时间区间">
                    <Datepicker timeType="daterange" style="width: 100%" @timeChange="timeChange" />
                  </el-form-item>
                  <el-form-item label="订单时间顺序">
                    <el-select
                      v-model="serachValue.order_by"
                      size="small"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in timeProsAndCons.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button
                      size="small"
                      type="primary"
                      style="width: 120px"
                      @click="getDataList('advanced')"
                      >确定</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </el-popover>
          </div>
        </el-col>
      </el-row>
      <div class="list-wrap">
        <m-table
          :data="dataList"
          :loading="loading"
          :pagination="{
            total: pages.total,
            pageSize: pages.page_size,
            currentPage: pages.page,
          }"
          @currentChange="handleCurrentChange"
        >
          <template #empty>
            <el-empty description="暂无数据"></el-empty>
          </template>
          <el-table-column label="邮箱" width="300px">
            <template #default="tableItem">
              <strong>
                <el-image
                  style="width: 23px; height: 17px; vertical-align: middle"
                  :src="tableItem.row.images"
                  fit="fill"
                >
                  <template #error>
                    <div class="image-slot">
                      <img src="../../../assets/img/UNKNOW.gif" alt="" />
                    </div>
                  </template>
                </el-image>
                {{ tableItem.row.email }}
              </strong>
            </template>
          </el-table-column>
          <el-table-column label="订单编号">
            <template #default="tableItem">
              <a :href="tableItem.row.order_url" target="_blank">{{ tableItem.row.order_item }}</a>
            </template>
          </el-table-column>
          <el-table-column label="订单时间" prop="created_time"></el-table-column>
          <el-table-column label="购买积分数" prop="credit"></el-table-column>
        </m-table>
      </div>
    </div>
  </div>
</template>

<script>
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import Datepicker from '@/components/datepicker/Index.vue';
import formSkeleton from '@/components/formSkeleton/FormSkeleton';
import { ref, watch } from 'vue';
import { Detail, PointStatis } from '../api/sellingpoints';

export default {
  name: 'SellingPoints',
  components: {
    'm-page-header': MPageHeader,
    Datepicker,
    formSkeleton,
  },
  setup() {
    // 初始化 列表数据
    const dataList = ref([]);
    const loading = ref(true);
    const dataStatis = ref({
      statis: '',
      currency: '',
    });
    const visible = ref(false);
    let pages = ref({
      page: 1,
      page_size: 10,
      total: 0,
    });
    const serachValue = ref({
      keyword: '', //搜索条件
      start_time: '', //时间
      end_time: '', //结束时间
      email: '', //邮箱
      order_by: '', //排序 desc / asc
    });
    const selectOptions = ref([
      {
        value: '',
        label: '全部',
      },
      {
        value: '-7',
        label: '最近7天',
      },
      {
        value: '-15',
        label: '最近15天',
      },
      {
        value: '-30',
        label: '最近30天',
      },
    ]);
    const timeSelect = ref('');
    const timeProsAndCons = ref({
      timeValue: '',
      options: [
        {
          value: '',
          label: '请选择',
        },
        {
          value: 'asc',
          label: '时间正序',
        },
        {
          value: 'desc',
          label: '时间倒序',
        },
      ],
    }); //时间正反序
    watch(timeSelect, val => {
      val ? (serachValue.value.start_time = getDay(val)) : (serachValue.value.start_time = val);
      statistics();
    });
    async function statistics() {
      const parms = {
        start_time: serachValue.value.start_time,
      };
      const { data } = await PointStatis(parms);
      dataStatis.value = data;
    }
    async function getDataList() {
      visible.value = false;
      const parms = {
        page: pages.value.page,
        page_size: pages.value.page_size,
        keyword: serachValue.value.keyword,
        start_time: serachValue.value.start_time,
        end_time: serachValue.value.end_time, //结束时间
        email: serachValue.value.email, //邮箱
        order_by: serachValue.value.order_by, //排序 desc / asc
      };
      const { data } = await Detail(parms, loading);
      dataList.value = data.list;
      pages.value.total = data.pages.total;
      //type === 'advanced' ? (serachValue.value = Object.assign({})) : '';
    }
    function handleCurrentChange(val) {
      pages.value.page = val;
      getDataList();
    }
    //获取多少天前日期
    function getDay(day) {
      let today = new Date();
      let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      let tYear = today.getFullYear();
      let tMonth = today.getMonth();
      let tDate = today.getDate();
      tMonth = doHandleMonth(tMonth + 1);
      tDate = doHandleMonth(tDate);
      return tYear + '-' + tMonth + '-' + tDate;
    }

    function doHandleMonth(month) {
      let m = month;
      if (month.toString().length == 1) {
        m = '0' + month;
      }
      return m;
    }

    function timeChange(val) {
      serachValue.value.start_time = val.startime;
      serachValue.value.end_time = val.endtime;
    }

    return {
      dataList,
      loading,
      dataStatis,
      selectOptions,
      timeSelect,
      timeProsAndCons,
      getDataList,
      handleCurrentChange,
      timeChange,
      statistics,
      visible,
      serachValue,
      pages,
    };
  },
  mounted() {
    this.getDataList();
    this.statistics();
    const self = this;
    document.body.onclick = function () {
      self.visible = false;
    };
  },
};
</script>

<style scoped lang="scss">
.main {
  padding: 15px 20px;
  a:visited {
    color: #0000ee;
  }
  .main_time {
    span {
      padding-right: 15px;
    }
  }

  .list-wrap {
    margin-top: 15px;
  }

  .main_panel {
    padding: 18px 0;

    .grid-content {
      height: 144px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border: solid 1px #dedede;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        flex: 1;
      }
      .main_svg {
        flex: 0 0 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f3f3;
          border-radius: 100%;
        }
        svg {
          width: 35px;
        }
      }

      .number {
        font-size: 36px;
        color: #333333;
        font-weight: bold;
        padding-bottom: 10px;
        .huobi {
          font-size: 20px;
        }
      }
    }
  }
}

.line {
  width: 100%;
  height: 10px;
  background-color: #f9f9f9;
}

::v-deep(.el-form-item__label) {
  line-height: 25px;
}
.el-form-item {
  margin-bottom: 5px;
}
@media screen and (max-width: 1280px) {
  .main .main_panel .grid-content .main_svg span {
    width: 40px;
    height: 40px;
  }
  .main .main_panel .grid-content .main_svg svg {
    width: 20px;
  }
  .main .main_panel .grid-content .number {
    font-size: 20px;
  }
  .main .main_panel .grid-content .number .huobi {
    font-size: 16px;
  }
  .main .main_panel .grid-content .main_svg {
    flex: 0 0 30%;
  }
}
</style>
